import React from 'react';
import {useState, useEffect, createContext, useContext, useCallback} from "react";
import axios from 'axios';

import AuthenticationService from '../AuthenticationService.js';

function ExpiryOptions_Get(P_ProductType, P_UnderlyingProductCode, C_Expiry){

    if (P_ProductType != null & P_ProductType.length > 0 & P_UnderlyingProductCode != null & P_UnderlyingProductCode.length > 0){

        var v_http_post_data = {
            Credential: AuthenticationService.getCurrentUser(),
            ProductType: P_ProductType,           
            UnderlyingProductCode: P_UnderlyingProductCode,
        }
    
        const url = sessionStorage.getItem('SiteURL') + 'api/PriceHistoryList/ExpiryList/Get/';
    
        axios.post(url, v_http_post_data)

        .then(resp => {
            const _expiry_list = resp.data.ExpiryList;
            const _expiry_options = _expiry_list.map((e, key) => {
                return <option key={key} value={e}>{e}</option>;
            });
            C_Expiry.setExpiry(_expiry_list[0]);
            C_Expiry.setExpiryOptions(_expiry_options);
        });

    } 

}

export{
    ExpiryOptions_Get,
};