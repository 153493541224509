// ButtonAppBar.js

import AuthenticationService from '../AuthenticationService.js';
import { useRoutes, A, navigate } from "hookrouter";
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import axios from 'axios';
import { useState } from "react";
import { w3cwebsocket as W3CWebSocket } from 'websocket';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Chip from '@mui/material/Chip';
import { red } from '@mui/material/colors';
import { green } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(0),
    },
    title: {
      flexGrow: 1,
    },
  }));

  export default function ButtonAppBar() {

    const classes = useStyles();
  
    const [anchorElNav, setAnchorElNav] = React.useState(null);
  
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    return (
      <div className={classes.root} >
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h7" className={classes.title}>
            期權策劃師
            </Typography>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu}><MenuIcon /></IconButton>
  
            <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
  
                  <MenuItem key='Home' onClick={handleCloseNavMenu}>
                    <Typography><A href='/home' style={{textDecoration: "none"}}>Home</A></Typography>
                  </MenuItem>
  
                  <MenuItem key='About' onClick={handleCloseNavMenu}>
                    <Typography><A href='/about' style={{textDecoration: "none"}}>About</A></Typography>
                  </MenuItem>
  
                  <MenuItem key='Contact' onClick={handleCloseNavMenu}>
                    <Typography><A href='/contact' style={{textDecoration: "none"}}>Contact</A></Typography>
                  </MenuItem>

                  <MenuItem key='OptionPlanner' onClick={handleCloseNavMenu}>
                    <Typography><A href='/optionplanner' style={{textDecoration: "none"}}>Option Planner</A></Typography>
                  </MenuItem>
  
                  <MenuItem key='OptionTable2' onClick={handleCloseNavMenu}>
                    <Typography><A href='/optiontable2' style={{textDecoration: "none"}}>Option Table</A></Typography>
                  </MenuItem>
  
                  <MenuItem key='MyList' onClick={handleCloseNavMenu}>
                    <Typography><A href='/mylist' style={{textDecoration: "none"}}>My List</A></Typography>
                  </MenuItem>
  
                  <MenuItem key='Training' onClick={handleCloseNavMenu}>
                    <Typography><A href='/training' style={{textDecoration: "none"}}>Training</A></Typography>
                  </MenuItem>

                  <MenuItem key='Training2' onClick={handleCloseNavMenu}>
                    <Typography><A href='/training2' style={{textDecoration: "none"}}>Training2</A></Typography>
                  </MenuItem>

                  <MenuItem key='Training3' onClick={handleCloseNavMenu}>
                    <Typography><A href='/training3' style={{textDecoration: "none"}}>Training3</A></Typography>
                  </MenuItem>

                  <MenuItem key='Test' onClick={handleCloseNavMenu}>
                    <Typography><A href='/test' style={{textDecoration: "none"}}>Test</A></Typography>
                  </MenuItem>

              </Menu>
  
          </Toolbar>
        </AppBar>
        <br/><br/><br/><br/>
      </div>
    );
  
}