function ProductTypeOptions_Get(P_Country, C_ProductType, C_UnderlyingProductCode, C_Expiry){

    if (P_Country != null & P_Country.length > 0){

        switch(P_Country){

            case 'HK':

                const _hk_product_type_list = ['IndexOption', 'StockOption'];
                const _hk_product_type_options = _hk_product_type_list.map((e, key) => {
                    return <option key={key} value={e}>{e}</option>;
                });
                let _hk_product_type = _hk_product_type_list[0];
                C_ProductType.setProductType(_hk_product_type);
                C_ProductType.setProductTypeOptions(_hk_product_type_options);
                C_UnderlyingProductCode.UnderlyingProductCodeOptions_Get(
                    P_Country, 
                    _hk_product_type, 
                    C_UnderlyingProductCode, 
                    C_Expiry
                );
                break;

            case 'US':

                const _us_product_type_list = ['StockOption'];
                const _us_product_type_options = _us_product_type_list.map((e, key) => {
                return <option key={key} value={e}>{e}</option>;
                });
                let _us_product_type = _us_product_type_list[0];
                C_ProductType.setProductType(_us_product_type);
                C_ProductType.setProductTypeOptions(_us_product_type_options);
                C_UnderlyingProductCode.UnderlyingProductCodeOptions_Get(
                    P_Country, 
                    _us_product_type, 
                    C_UnderlyingProductCode, 
                    C_Expiry
                );
                break;

        }            

    }        

};

export{
    ProductTypeOptions_Get,
};