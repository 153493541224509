import React from 'react';
import {useState, useEffect, createContext, useContext, useCallback} from "react";
import axios from 'axios';

import AuthenticationService from '../AuthenticationService.js';

function UnderlyingProductCodeOptions_Get(P_Country, P_ProductType, C_UnderlyingProductCode, C_Expiry){

    if (P_Country != null & P_Country.length > 0 & P_ProductType != null & P_ProductType.length > 0){

        var v_http_post_data = {
            Credential: AuthenticationService.getCurrentUser(),
            Country: P_Country,
            ProductType: P_ProductType,           
        }
    
        const url = sessionStorage.getItem('SiteURL') + 'api/PriceHistoryList/UnderlyingProductList/Get/';
    
        axios.post(url, v_http_post_data)

        .then(resp => {
            const _underlying_product_list = resp.data.UnderlyingProductList;
            const _underlying_product_code_options = _underlying_product_list.map((e, key) => {
                return <option key={key} value={e}>{e}</option>;
            });
            let _underlying_product_code = _underlying_product_list[0];
            C_UnderlyingProductCode.setUnderlyingProductCode(_underlying_product_code);
            C_UnderlyingProductCode.setUnderlyingProductCodeOptions(_underlying_product_code_options);
            C_Expiry.ExpiryOptions_Get(
                P_ProductType, 
                _underlying_product_code, 
                C_Expiry
            );
        });            

    }   

}

export{
    UnderlyingProductCodeOptions_Get,
};