// CombinationListItem.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CombinationDetails2 from './CombinationDetails2.js';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import { Alert, AlertTitle } from '@material-ui/lab';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, InputLabel } from '@material-ui/core';
import TextField from '@mui/material/TextField';

import AuthenticationService from '../AuthenticationService.js';
import { LongUnderlyingProductValue, LongExpiry } from './Common.js';

function LeftRightFieldValue(field, value){

  return(
  <Grid container spacing={1}>
    <Grid item xs={6} lg={6}>
      {field}
    </Grid>
    <Grid item xs={6} lg={6}>
      <Box display="flex" justifyContent="flex-end">{value}</Box>
    </Grid>
  </Grid>
  );

}

function AverageWidth(ShowLeft,ShowCenter,ShowRight){

  let w = 0;

  if (ShowLeft) w += 1;
  if (ShowCenter) w += 1;
  if (ShowRight) w += 1;

  switch(w){
    case 0:
      return 0;
    case 1:
      return 6;
    case 2:
      return 4;
    case 3:
      return 3;
  }

}

export default function CombinationListItem(props){

  const { 
    Left7, 
    Left6, 
    Left5, 
    Left4, 
    Left3, 
    Left2, 
    Left1, 
    Center, 
    Right1, 
    Right2, 
    Right3, 
    Right4, 
    Right5, 
    Right6, 
    Right7, 
    CombinationListItem, 
    UnderlyingProductCode, 
    ProductType4UnderlyingProductValue, 
    UnderlyingProductValue, 
    Expiry, 
    Multiplier,
    ProductType, 
    ListOptionDetailsList, // (N rows, SC+LC+SP+LP per row)
    DisplayXReferenceLineUnderlyingProductValue,
    DisplayDetails,
    ShowLeft,
    ShowCenter,
    ShowRight,
  } = props;

  const [OptionDetailsList4Left7, setOptionDetailsList4Left7] = useState([]);
  const [OptionDetailsList4Left6, setOptionDetailsList4Left6] = useState([]);
  const [OptionDetailsList4Left5, setOptionDetailsList4Left5] = useState([]);
  const [OptionDetailsList4Left4, setOptionDetailsList4Left4] = useState([]);
  const [OptionDetailsList4Left3, setOptionDetailsList4Left3] = useState([]);
  const [OptionDetailsList4Left2, setOptionDetailsList4Left2] = useState([]);
  const [OptionDetailsList4Left1, setOptionDetailsList4Left1] = useState([]);
  const [OptionDetailsList4Center, setOptionDetailsList4Center] = useState([]);
  const [OptionDetailsList4Right1, setOptionDetailsList4Right1] = useState([]);
  const [OptionDetailsList4Right2, setOptionDetailsList4Right2] = useState([]);
  const [OptionDetailsList4Right3, setOptionDetailsList4Right3] = useState([]);
  const [OptionDetailsList4Right4, setOptionDetailsList4Right4] = useState([]);
  const [OptionDetailsList4Right5, setOptionDetailsList4Right5] = useState([]);
  const [OptionDetailsList4Right6, setOptionDetailsList4Right6] = useState([]);
  const [OptionDetailsList4Right7, setOptionDetailsList4Right7] = useState([]);
  
  const [OptionDetailsList, setOptionDetailsList] = useState([]);

  function onChange_CostPrice(P_CostPrice, P_ID, P_OptionDetailsList, P_Position){    

    var V_OptionDetailsList = [...P_OptionDetailsList];

    V_OptionDetailsList[P_ID].CostPrice = parseFloat(P_CostPrice);
    V_OptionDetailsList[P_ID].TotalCostAvg = V_OptionDetailsList[P_ID].CostShare * V_OptionDetailsList[P_ID].CostPrice * (-1);

    switch(P_Position){
      case 'Left7':
        setOptionDetailsList4Left7(V_OptionDetailsList);
        break;
      case 'Left6':
        setOptionDetailsList4Left6(V_OptionDetailsList);
        break;
      case 'Left5':
        setOptionDetailsList4Left5(V_OptionDetailsList);
        break;                        
      case 'Left4':
        setOptionDetailsList4Left4(V_OptionDetailsList);
        break;
      case 'Left3':
        setOptionDetailsList4Left3(V_OptionDetailsList);
        break;
      case 'Left2':
        setOptionDetailsList4Left2(V_OptionDetailsList);
        break;                      
      case 'Left1':
        setOptionDetailsList4Left1(V_OptionDetailsList);
        break;
      case 'Center':
        setOptionDetailsList4Center(V_OptionDetailsList);
        break;   
      case 'Right1':
        setOptionDetailsList4Right1(V_OptionDetailsList);
        break;   
      case 'Right2':
        setOptionDetailsList4Right2(V_OptionDetailsList);
        break;   
      case 'Right3':
        setOptionDetailsList4Right3(V_OptionDetailsList);
        break;   
      case 'Right4':
        setOptionDetailsList4Right4(V_OptionDetailsList);
        break;   
      case 'Right5':
        setOptionDetailsList4Right5(V_OptionDetailsList);
        break;   
      case 'Right6':
        setOptionDetailsList4Right6(V_OptionDetailsList);
        break;   
      case 'Right7':
        setOptionDetailsList4Right7(V_OptionDetailsList);
        break;           
      }

    // setOptionDetailsList4Center(V_OptionDetailsList);

  }

  function TextFieldPush(_option_details_list_item, key, P_Position){

    var V_OptionDetailsList = [];

    switch(P_Position){
      case 'Left7':
        V_OptionDetailsList = OptionDetailsList4Left7;
        break;
      case 'Left6':
        V_OptionDetailsList = OptionDetailsList4Left6;
        break;
      case 'Left5':
        V_OptionDetailsList = OptionDetailsList4Left5;
        break;
      case 'Left4':
        V_OptionDetailsList = OptionDetailsList4Left4;
        break;
      case 'Left3':
        V_OptionDetailsList = OptionDetailsList4Left3;
        break;
      case 'Left2':
        V_OptionDetailsList = OptionDetailsList4Left2;
        break;
      case 'Left1':
        V_OptionDetailsList = OptionDetailsList4Left1;
        break;
      case 'Center':
        V_OptionDetailsList = OptionDetailsList4Center;
        break;
      case 'Right1':
        V_OptionDetailsList = OptionDetailsList4Right1;
        break;        
      case 'Right2':
        V_OptionDetailsList = OptionDetailsList4Right2;
        break;  
      case 'Right3':
        V_OptionDetailsList = OptionDetailsList4Right3;
        break;  
      case 'Right4':
        V_OptionDetailsList = OptionDetailsList4Right4;
        break;    
      case 'Right5':
        V_OptionDetailsList = OptionDetailsList4Right5;
        break; 
      case 'Right6':
        V_OptionDetailsList = OptionDetailsList4Right6;
        break;     
      case 'Right7':
        V_OptionDetailsList = OptionDetailsList4Right7;
        break;           
    }

    var V_ProductCode = _option_details_list_item.ProductCode;
    V_ProductCode = _option_details_list_item.OptionType + ' ' + _option_details_list_item.Strike;

    return(
      <React.Fragment>
        <br/>
        <Box width="100%">
          <TextField id={_option_details_list_item.ProductCode} label={V_ProductCode} defaultValue={_option_details_list_item.CostPrice} onChange={e => onChange_CostPrice(e.target.value, key, V_OptionDetailsList, P_Position)}/>
        </Box>         
      </React.Fragment>
    )

  }

  function AllTextFields_Show(P_Position){

    switch(P_Position){

      case 'Left7':
        
      if (OptionDetailsList4Left7.length > 0 & Left7 == true){
     
        var V_TextFields = [];

        {OptionDetailsList4Left7.map((_option_details_list_item, key) => (
          V_TextFields.push(TextFieldPush(_option_details_list_item, key, P_Position))
        ))}
     
        return(
          <React.Fragment>
          <Box p={2} width="100%">
            {V_TextFields}
          </Box>
        </React.Fragment>
        )
      
      }  
        else{
          return(
            <React.Fragment>
            </React.Fragment>
          )
        }

        break;

      case 'Left6':
        
      if (OptionDetailsList4Left6.length > 0 & Left6 == true){
     
        var V_TextFields = [];

        {OptionDetailsList4Left6.map((_option_details_list_item, key) => (
          V_TextFields.push(TextFieldPush(_option_details_list_item, key, P_Position))
        ))}
     
        return(
          <React.Fragment>
          <Box p={2} width="100%">
            {V_TextFields}
          </Box>
        </React.Fragment>
        )
      
      }  
        else{
          return(
            <React.Fragment>
            </React.Fragment>
          )
        }

        break;

      case 'Left5':
        
      if (OptionDetailsList4Left5.length > 0 & Left5 == true){
     
        var V_TextFields = [];

        {OptionDetailsList4Left5.map((_option_details_list_item, key) => (
          V_TextFields.push(TextFieldPush(_option_details_list_item, key, P_Position))
        ))}
     
        return(
          <React.Fragment>
          <Box p={2} width="100%">
            {V_TextFields}
          </Box>
        </React.Fragment>
        )
      
      }  
        else{
          return(
            <React.Fragment>
            </React.Fragment>
          )
        }

        break;

      case 'Left4':
        
      if (OptionDetailsList4Left4.length > 0 & Left4 == true){
     
        var V_TextFields = [];

        {OptionDetailsList4Left4.map((_option_details_list_item, key) => (
          V_TextFields.push(TextFieldPush(_option_details_list_item, key, P_Position))
        ))}
     
        return(
          <React.Fragment>
          <Box p={2} width="100%">
            {V_TextFields}
          </Box>
        </React.Fragment>
        )
      
      }  
        else{
          return(
            <React.Fragment>
            </React.Fragment>
          )
        }

        break;

      case 'Left3':
        
      if (OptionDetailsList4Left3.length > 0 & Left3 == true){
     
        var V_TextFields = [];

        {OptionDetailsList4Left3.map((_option_details_list_item, key) => (
          V_TextFields.push(TextFieldPush(_option_details_list_item, key, P_Position))
        ))}
     
        return(
          <React.Fragment>
          <Box p={2} width="100%">
            {V_TextFields}
          </Box>
        </React.Fragment>
        )
      
      }  
        else{
          return(
            <React.Fragment>
            </React.Fragment>
          )
        }

        break;

      case 'Left2':
        
      if (OptionDetailsList4Left2.length > 0 & Left2 == true){
     
        var V_TextFields = [];

        {OptionDetailsList4Left2.map((_option_details_list_item, key) => (
          V_TextFields.push(TextFieldPush(_option_details_list_item, key, P_Position))
        ))}
     
        return(
          <React.Fragment>
          <Box p={2} width="100%">
            {V_TextFields}
          </Box>
        </React.Fragment>
        )
      
      }  
        else{
          return(
            <React.Fragment>
            </React.Fragment>
          )
        }

        break;

      case 'Left1':
        
      if (OptionDetailsList4Left1.length > 0 & Left1 == true){
     
        var V_TextFields = [];

        {OptionDetailsList4Left1.map((_option_details_list_item, key) => (
          V_TextFields.push(TextFieldPush(_option_details_list_item, key, P_Position))
        ))}
     
        return(
          <React.Fragment>
          <Box p={2} width="100%">
            {V_TextFields}
          </Box>
        </React.Fragment>
        )
      
      }  
        else{
          return(
            <React.Fragment>
            </React.Fragment>
          )
        }

        break;

      case 'Center':
        
        if (OptionDetailsList4Center.length > 0 & Center == true){
     
          var V_TextFields = [];
  
          {OptionDetailsList4Center.map((_option_details_list_item, key) => (
            V_TextFields.push(TextFieldPush(_option_details_list_item, key, P_Position))
          ))}
       
          return(
            <React.Fragment>
              <Box p={2} width="100%">
                {V_TextFields}
              </Box>
            </React.Fragment>
          )
        
        }    
        else{
          return(
            <React.Fragment>
            </React.Fragment>
          )
        }

        break;

      case 'Right1':
        
      if (OptionDetailsList4Right1.length > 0 & Right1 == true){
     
        var V_TextFields = [];

        {OptionDetailsList4Right1.map((_option_details_list_item, key) => (
          V_TextFields.push(TextFieldPush(_option_details_list_item, key, P_Position))
        ))}
     
        return(
          <React.Fragment>
            <Box p={2} width="100%">
              {V_TextFields}
            </Box>
          </React.Fragment>
        )
      
      }     
        else{
          return(
            <React.Fragment>
            </React.Fragment>
          )
        }

        break;    

      case 'Right2':
        
        if (OptionDetailsList4Right2.length > 0 & Right2 == true){
       
          var V_TextFields = [];
  
          {OptionDetailsList4Right2.map((_option_details_list_item, key) => (
            V_TextFields.push(TextFieldPush(_option_details_list_item, key, P_Position))
          ))}
       
          return(
            <React.Fragment>
              <Box p={2} width="100%">
                {V_TextFields}
              </Box>
            </React.Fragment>
          )
        
        }     
        else{
          return(
            <React.Fragment>
            </React.Fragment>
          )
        }
  
        break;         

      case 'Right3':
        
        if (OptionDetailsList4Right3.length > 0 & Right3 == true){
         
          var V_TextFields = [];
    
          {OptionDetailsList4Right3.map((_option_details_list_item, key) => (
            V_TextFields.push(TextFieldPush(_option_details_list_item, key, P_Position))
          ))}
         
          return(
            <React.Fragment>
              <Box p={2} width="100%">
                {V_TextFields}
              </Box>
            </React.Fragment>
          )
          
        }     
        else{
          return(
            <React.Fragment>
            </React.Fragment>
          )
        }
    
        break;    

      case 'Right4':
        
        if (OptionDetailsList4Right4.length > 0 & Right4 == true){
           
          var V_TextFields = [];
      
          {OptionDetailsList4Right4.map((_option_details_list_item, key) => (
            V_TextFields.push(TextFieldPush(_option_details_list_item, key, P_Position))
          ))}
           
          return(
            <React.Fragment>
              <Box p={2} width="100%">
                {V_TextFields}
              </Box>
            </React.Fragment>
          )
            
        }     
        else{
          return(
            <React.Fragment>
            </React.Fragment>
          )
        }
      
        break;    

      case 'Right5':
        
        if (OptionDetailsList4Right5.length > 0 & Right5 == true){
             
          var V_TextFields = [];

          {OptionDetailsList4Right5.map((_option_details_list_item, key) => (
            V_TextFields.push(TextFieldPush(_option_details_list_item, key, P_Position))
          ))}
             
          return(
            <React.Fragment>
              <Box p={2} width="100%">
                {V_TextFields}
              </Box>
            </React.Fragment>
          )
              
        }     
        else{
          return(
            <React.Fragment>
            </React.Fragment>
          )
        }
        
        break;  

      case 'Right6':
        
        if (OptionDetailsList4Right6.length > 0 & Right6 == true){
               
          var V_TextFields = [];
          
          {OptionDetailsList4Right6.map((_option_details_list_item, key) => (
            V_TextFields.push(TextFieldPush(_option_details_list_item, key, P_Position))
          ))}
               
          return(
            <React.Fragment>
              <Box p={2} width="100%">
                {V_TextFields}
              </Box>
            </React.Fragment>
          )
                
        }     
        else{
          return(
            <React.Fragment>
            </React.Fragment>
          )
        }
          
        break;  
                  
      case 'Right7':
        
        if (OptionDetailsList4Right7.length > 0 & Right7 == true){
                 
          var V_TextFields = [];
            
          {OptionDetailsList4Right7.map((_option_details_list_item, key) => (
            V_TextFields.push(TextFieldPush(_option_details_list_item, key, P_Position))
          ))}
                 
          return(
            <React.Fragment>
              <Box p={2} width="100%">
                {V_TextFields}
              </Box>
            </React.Fragment>
          )
                  
        }     
        else{
          return(
            <React.Fragment>
            </React.Fragment>
          )
        }
            
        break;                    

      case null:

        return(
          <React.Fragment>
          </React.Fragment>
        )

    }    
  
  }

  function SubmitAction(){
    setOptionDetailsList(OptionDetailsList4Center);
  }

  useEffect(() => {    
    var i = 0;
  }, [OptionDetailsList]);

  useEffect(() => {    
    var i = 0;
  }, [OptionDetailsList4Left7, OptionDetailsList4Left6, OptionDetailsList4Left5, OptionDetailsList4Left4, OptionDetailsList4Left3, OptionDetailsList4Left2, OptionDetailsList4Left1, OptionDetailsList4Center, OptionDetailsList4Right1, OptionDetailsList4Right2, OptionDetailsList4Right3, OptionDetailsList4Right4, OptionDetailsList4Right5, OptionDetailsList4Right6, OptionDetailsList4Right7]);

  function CombinationDisplay(P_Position, P_Display, V_OptionDetailsList){

    if (ListOptionDetailsList.length > 0 & V_OptionDetailsList.length == 0){

      var v_http_post_data = {
          Credential: AuthenticationService.getCurrentUser(),
          CombinationID: CombinationListItem.ID,
          Position: P_Position,   
          ListOptionDetailsList: ListOptionDetailsList,
          Last: -1,
      }

      const url = sessionStorage.getItem('SiteURL') + 'api/OptionDetailsList/OptionStrategyFinder/';

      axios.post(url, v_http_post_data)

      .then(resp => {

        let _option_details_list = resp.data;

        switch(P_Position){
          case 'Left7':
            setOptionDetailsList4Left7(_option_details_list);
            break;
          case 'Left6':
            setOptionDetailsList4Left6(_option_details_list);
            break;
          case 'Left5':
            setOptionDetailsList4Left5(_option_details_list);
            break;                                    
          case 'Left4':
            setOptionDetailsList4Left4(_option_details_list);
            break;
          case 'Left3':
            setOptionDetailsList4Left3(_option_details_list);
            break;
          case 'Left2':
            setOptionDetailsList4Left2(_option_details_list);
            break;
          case 'Left1':
            setOptionDetailsList4Left1(_option_details_list);
            break;
          case 'Center':
            setOptionDetailsList4Center(_option_details_list);
            break;   
          case 'Right1':
            setOptionDetailsList4Right1(_option_details_list);
            break;   
          case 'Right2':
            setOptionDetailsList4Right2(_option_details_list);
            break; 
          case 'Right3':
            setOptionDetailsList4Right3(_option_details_list);
            break; 
          case 'Right4':
            setOptionDetailsList4Right4(_option_details_list);
            break;           
          case 'Right5':
            setOptionDetailsList4Right5(_option_details_list);
            break;  
          case 'Right6':
            setOptionDetailsList4Right6(_option_details_list);
            break;  
          case 'Right7':
            setOptionDetailsList4Right7(_option_details_list);
            break;                                        
          }
          
      });

    }

    // setOptionDetailsList(OptionDetailsList4Center);

    if (V_OptionDetailsList.length > 0 & P_Display == true){
      return (
        <React.Fragment>
          <Grid item xs={12} lg={AverageWidth(ShowLeft,ShowCenter,ShowRight)}>
            <br/>
            {AllTextFields_Show(P_Position)}
            <br/>
            <CombinationDetails2
              CombinationID={CombinationListItem.ID} 
              ProductType={ProductType} 
              Position={P_Position}
              ListOptionDetailsList={ListOptionDetailsList} 
              OptionDetailsList={V_OptionDetailsList}
              UnderlyingProductCode={UnderlyingProductCode} 
              Expiry={Expiry} 
              Multiplier={Multiplier}
              UnderlyingProductValue={UnderlyingProductValue} 
              TimeStatus={'Current'}
              GroupNumber={-1}
              REACT={true}
              BaseContract={1}
              DisplayXReferenceLineUnderlyingProductValue={DisplayXReferenceLineUnderlyingProductValue}
              DisplayDetails={DisplayDetails}
            />
          </Grid>
          {/* <Grid item xs={12} lg={AverageWidth(Left, Center, Right)} align='center'>
            <Button variant="contained" color='primary' onClick={() => SubmitAction()}>{'Refresh'}</Button>
          </Grid> */}
        </React.Fragment>
      );
    }
    else{
      return null;
    }
  
  }

  function Summary(){
  
    return(
      
        <Grid item xs={12} lg={AverageWidth(ShowLeft,ShowCenter,ShowRight)}>
  
          <Alert severity='info' Variant='filled' icon={false}>
              <AlertTitle>{CombinationListItem.ID}</AlertTitle>
              <span>{CombinationListItem.ChineseName}</span><br/>
              <span>{CombinationListItem.Remark}</span><br/>                      
          </Alert>
          
          <br/>
          {LeftRightFieldValue('相關產品：', UnderlyingProductCode)}
          {LeftRightFieldValue('現貨價：', LongUnderlyingProductValue(ProductType4UnderlyingProductValue, UnderlyingProductValue))}
          {LeftRightFieldValue('到期日：', LongExpiry(Expiry))}                  
          {/* <br/> */}
  
        </Grid>
    )
  
  };

  return(
    
    <div>

    <Box border={1} borderColor="grey.300">

      {/* <Box pb={0} pt={2} pl={2} pr={2} m={0}>   
      
        <Grid container>
          {Summary()}
        </Grid>
    
      </Box> */}

      <Box pb={2} pt={2} pl={0} pr={0} m={0}>   
      
        <Grid container>          
          {CombinationDisplay('Left7', Left7, OptionDetailsList4Left7)}
          {CombinationDisplay('Left6', Left6, OptionDetailsList4Left6)}
        </Grid>
    
      </Box>

      <Box pb={2} pt={2} pl={0} pr={0} m={0}>   
      
        <Grid container>          
          {CombinationDisplay('Left5', Left5, OptionDetailsList4Left5)}
          {CombinationDisplay('Left4', Left4, OptionDetailsList4Left4)}
        </Grid>
    
      </Box>

      <Box pb={2} pt={2} pl={0} pr={0} m={0}>   
      
        <Grid container>          
          {CombinationDisplay('Left3', Left3, OptionDetailsList4Left3)}
          {CombinationDisplay('Left2', Left2, OptionDetailsList4Left2)}
        </Grid>
      
      </Box>

      <Box pb={2} pt={2} pl={0} pr={0} m={0}>   
      
        <Grid container>          
          {CombinationDisplay('Left1', Left1, OptionDetailsList4Left1)}
          {CombinationDisplay('Center', Center, OptionDetailsList4Center)}
        </Grid>
      
      </Box>

      <Box pb={2} pt={2} pl={0} pr={0} m={0}>   
      
        <Grid container>          
          {CombinationDisplay('Right1', Right1, OptionDetailsList4Right1)}
          {CombinationDisplay('Right2', Right2, OptionDetailsList4Right2)}
        </Grid>
      
      </Box>

      <Box pb={2} pt={2} pl={0} pr={0} m={0}>   
      
        <Grid container>          
          {CombinationDisplay('Right3', Right3, OptionDetailsList4Right3)}
          {CombinationDisplay('Right4', Right4, OptionDetailsList4Right4)}                             
        </Grid>
      
      </Box>

      <Box pb={2} pt={2} pl={0} pr={0} m={0}>   
      
        <Grid container>          
          {CombinationDisplay('Right5', Right5, OptionDetailsList4Right5)}
          {CombinationDisplay('Right6', Right6, OptionDetailsList4Right6)}
        </Grid>
      
      </Box>

      <Box pb={2} pt={2} pl={0} pr={0} m={0}>   
      
        <Grid container>          
          {CombinationDisplay('Right7', Right7, OptionDetailsList4Right7)}                             
        </Grid>
      
      </Box>


    </Box>
    
    <br/><br/><br/>

    </div>

  )

}