import React from 'react';
import ReactDOM from 'react-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import Box from '@material-ui/core/Box';

export default function LineChart1(props){

    const { 
        Position, 
        chartdata, 
        Domain, 
        XReferenceLines, 
        YReferenceLines, 
        XReferenceLineUnderlyingProductValue, 
        YReferenceLineZero, 
        XReferenceDots 
    } = props;

    const BoxLabel = (p_position) => {

        let PositionTitle;
        let justifyContent;

        switch(p_position){
            case 'Left':
                PositionTitle = '現貨價偏右';
                justifyContent="flex-start";
                break;
            case 'Center':
                PositionTitle = '現貨價置中';
                justifyContent="center";
                break;
            case 'Right':
                PositionTitle = '現貨價偏左';
                justifyContent="flex-end";
                break;
        }

        return (
            <Box display="flex" justifyContent={justifyContent}>
            <Box m={1} color="grey.300">{PositionTitle}</Box>
            </Box>
        );

      }

    return( // height in div below will affect the height of the chart; fontsize in div below will affect all the fonts in the chart
            <div style={{ height: "50vh", fontSize: "25px" }}>

                    {BoxLabel(Position)}

                    <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                            // allowDecimals={true}
                            width={500}
                            height={300}
                            data={chartdata}
                            margin={{
                                top: 100, // should be larger if full strike values at the top cannot be completely displayed
                                right: 10,
                                left: 30,
                                bottom: 50,
                            }}
                            >
                            <XAxis dataKey="x" hide={true} />
                            <YAxis  dataKey="y" hide={true} domain={Domain}/>
                          {/*   <Tooltip /> */}
                            <Legend />                                
                            
                            {XReferenceLines}
                            {YReferenceLines}
                            
                            <Line type="monotone" dataKey="y" stroke="#bdbdbd" strokeWidth={5} legendType="none" dot={false} isAnimationActive={false}/>

                            {XReferenceLineUnderlyingProductValue}
                            {YReferenceLineZero}

                            {XReferenceDots}

                            </LineChart>
                    </ResponsiveContainer>

            </div>
    )

}