import React from 'react';
import {useState, useEffect, createContext, useContext, useCallback} from "react";

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';

function Select_Country(props){

    const{
        Country,
        Country_Callback,
    } = props;

    function onChange_Country(P_Country){
        Country_Callback(P_Country);
    }

    return(
        <Grid item xs={3}>
            <FormControl style={{ width: '100%' }}>
                <InputLabel id="CountryLabel">Country</InputLabel>
                <Select native='true' value={Country} name="Country" id="Country" labelId="CountryLabel" onChange={e => onChange_Country(e.target.value)}>
                    <option key={0} value={'HK'}>HK</option>
                    <option key={1} value={'US'}>US</option>
                </Select>
            </FormControl>
        </Grid>
    )

}

export{
    Select_Country,
};