import React from 'react';
import {useState, useEffect, createContext, useContext, useCallback} from "react";

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';

function Select_ProductType(props){

    const{
        ProductType,
        ProductTypeOptions,
        ProductType_Callback,
    } = props;

    function onChange_ProductType(P_ProductType){
        ProductType_Callback(P_ProductType);
    }

    return(
        <Grid item xs={3}>
            <FormControl style={{ width: '100%' }}>
                <InputLabel id="ProductTypeLabel">ProductType</InputLabel>
                <Select native='true' value={ProductType} name="ProductType" id="ProductType" labelId="ProductTypeLabel" onChange={e => onChange_ProductType(e.target.value)}>
                    {ProductTypeOptions}
                </Select>
            </FormControl>
        </Grid>
    )

}

export{
    Select_ProductType,
};