import React, { Component } from 'react';
import { useRoutes, A } from "hookrouter";
import Routes from './Routes';

import SignIn from './SignIn';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Error from './components/Error';
import Navigation from './components/Navigation';

function App() {
  
  // <"proxy": "https://v4restfulswms.johnc3v16.com/",> in package.json

  sessionStorage.setItem('SiteURL', 'https://v4restfulswms.johnc3v16.com');
  // sessionStorage.setItem('SiteURL', 'http://localhost:22222/');
  // sessionStorage.setItem('SiteURL', '');

  sessionStorage.setItem('WebSocketURL', 'wss://wshub.kennethysyu.com');
  // sessionStorage.setItem('WebSocketURL', 'ws://localhost:8080');
  
  const routeResult = useRoutes(Routes);
  return routeResult;

}

export default App;