// UnderlyingProductLineChart.js

import { BrowserRouter, Route } from "react-router-dom";
import React from 'react';
import axios from 'axios';
import { useState, useContext } from "react";
import ReactDOM from 'react-dom';
import MaterialTable from "material-table";
import { useRoutes, A, navigate } from "hookrouter";
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';

import { InputLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { sizing } from '@material-ui/system';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { DataGrid } from '@material-ui/data-grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';

import StrikeReferenceLines_Get from './StrikeReferenceLines_Get.js';
import { ChartDataDateTimeHKContext } from '../Context.js';
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, LongExpiry, ShortExpiry, Expiry_Get, date2string, UnderlyingProductValue_Get, getResponse, GroupSummaryCostValueSum, SetBidAskValue } from './Common.js';

function ProfitLineChart2(props) {

    const { ChartData, Profit, Domain } = props;

    if (Domain.length > 0){

        return(

            <Card variant="outlined">

                <CardMedia>

                    <div style={{ height: "30vh" }}>                    
                    <br/>
                        <ResponsiveContainer width="100%" height="100%">
                            
                            <LineChart width={350} height={350} data={ChartData} margin={{top: 0, right: 10, left: 10, bottom: 75}}>
                                <XAxis dataKey="x" hide={true} />
                                <YAxis dataKey="y" hide={true} domain={Domain}/>
                                <Legend />                                
                                <ReferenceLine y={Domain[1]} label={{ value: LongUnderlyingProductValue("Index", Domain[1]), angle: 0, fontSize: 12, position: 'insideTopLeft', fill: 'grey'}} stroke="lightgrey"/>
                                <ReferenceLine y={Domain[0]} label={{ value: LongUnderlyingProductValue("Index", Domain[0]), angle: 0, fontSize: 12, position: 'insideTopLeft', fill: 'grey'}} stroke="lightgrey"/>
                                <Line type="monotone" dataKey="y" stroke="black" strokeWidth={1} legendType="none" dot={false} isAnimationActive={false}/>
                            </LineChart>

                        </ResponsiveContainer>

                    </div>

                </CardMedia>

                <CardContent style={{ backgroundColor: '#f5f5f5' }}>

                    <Grid container spacing={1}>
                        <Grid item align="left" xs={12} lg={12}>
                            {'Profit: ' + LongUnderlyingProductValue("Index", Profit)}
                        </Grid>
                    </Grid> 

                </CardContent>

            </Card>        

        )  

    }
    else{

        return(
            <React.Fragment>
            </React.Fragment>
        )
        
    }

}

export default ProfitLineChart2;