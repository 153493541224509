import React from 'react';
import {useState, useEffect, createContext, useContext, useCallback} from "react";

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';

import {Select_Country} from "../functions3/Select_Country.js";
import {Select_ProductType} from "../functions3/Select_ProductType.js";
import {Select_UnderlyingProductCode} from "../functions3/Select_UnderlyingProductCode.js";
import {Select_Expiry} from "../functions3/Select_Expiry.js";

function Select3(props){

    const{
        Country,
        Country_Callback,
        ProductType,
        ProductTypeOptions,
        ProductType_Callback,
        UnderlyingProductCode,
        UnderlyingProductCodeOptions,
        UnderlyingProductCode_Callback,
        Expiry,
        ExpiryOptions,
        Expiry_Callback,
    } = props;

    // function onChange_Expiry(P_Expiry){
    //     Expiry_Callback(P_Expiry);
    // }

    // function onChange_UnderlyingProductCode(P_UnderlyingProductCode){
    //     UnderlyingProductCode_Callback(P_UnderlyingProductCode);
    // }

    // function onChange_ProductType(P_ProductType){
    //     ProductType_Callback(P_ProductType);
    // }

    // function onChange_Country(P_Country){
    //     Country_Callback(P_Country);
    // }

    // const Select_Expiry = useCallback(() => {
    //     return(
    //         <Grid item xs={3}>
    //             <FormControl style={{ width: '100%' }}>
    //                 <InputLabel id="ExpiryLabel">Expiry</InputLabel>
    //                 <Select native='true' value={Expiry} name="Expiry" id="Expiry" labelId="ExpiryLabel" onChange={e => onChange_Expiry(e.target.value)}>
    //                     {ExpiryOptions}
    //                 </Select>
    //             </FormControl>
    //         </Grid>
    //     )
    // }, [Expiry, ExpiryOptions]);

    // const Select_UnderlyingProductCode = useCallback(() => {
    //     return(
    //         <Grid item xs={3}>
    //             <FormControl style={{ width: '100%' }}>
    //                 <InputLabel id="UnderlyingProductCodeLabel">UnderlyingProductCode</InputLabel>
    //                 <Select native='true' value={UnderlyingProductCode} name="UnderlyingProductCode" id="UnderlyingProductCode" labelId="UnderlyingProductCodeLabel" onChange={e => onChange_UnderlyingProductCode(e.target.value)}>
    //                     {UnderlyingProductCodeOptions}
    //                 </Select>
    //             </FormControl>
    //         </Grid>
    //     )
    // }, [UnderlyingProductCode, UnderlyingProductCodeOptions]);

    // const Select_ProductType = useCallback(() => {
    //     return(
    //         <Grid item xs={3}>
    //             <FormControl style={{ width: '100%' }}>
    //                 <InputLabel id="ProductTypeLabel">ProductType</InputLabel>
    //                 <Select native='true' value={ProductType} name="ProductType" id="ProductType" labelId="ProductTypeLabel" onChange={e => onChange_ProductType(e.target.value)}>
    //                     {ProductTypeOptions}
    //                 </Select>
    //             </FormControl>
    //         </Grid>
    //     )
    // }, [ProductType, ProductTypeOptions]);

    // const Select_Country = useCallback(() => {
    //     return(
    //         <Grid item xs={3}>
    //             <FormControl style={{ width: '100%' }}>
    //                 <InputLabel id="CountryLabel">Country</InputLabel>
    //                 <Select native='true' value={Country} name="Country" id="Country" labelId="CountryLabel" onChange={e => onChange_Country(e.target.value)}>
    //                     <option key={0} value={'HK'}>HK</option>
    //                     <option key={1} value={'US'}>US</option>
    //                 </Select>
    //             </FormControl>
    //         </Grid>
    //     )
    // });

    return(
        <React.Fragment>
            <Select_Country Country={Country} Country_Callback={Country_Callback} />
            <br/>
            <Select_ProductType ProductType={ProductType} ProductTypeOptions={ProductTypeOptions} ProductType_Callback={ProductType_Callback}/>
            <br/>
            <Select_UnderlyingProductCode UnderlyingProductCode={UnderlyingProductCode} UnderlyingProductCodeOptions={UnderlyingProductCodeOptions} UnderlyingProductCode_Callback={UnderlyingProductCode_Callback} />
            <br/>
            <Select_Expiry Expiry={Expiry} ExpiryOptions={ExpiryOptions} Expiry_Callback={Expiry_Callback} />
        </React.Fragment>
    )

}

export{
    Select3,
};