import React from 'react';
import {useState, useEffect, createContext, useContext, useCallback} from "react";
import axios from 'axios';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import ButtonAppBar from "../functions/ButtonAppBar.js";
import AuthenticationService from '../AuthenticationService.js';
import {ListOptionDetailsList_Get2} from '../functions/Common.js';
import CombinationListItem from '../functions/CombinationListItem.js';

import {Select3} from "../functions3/Select3.js";
import {Select_Combination} from "../functions3/Select_Combination.js";
import {ProductTypeOptions_Get} from "../functions3/ProductTypeOptions_Get.js";
import {UnderlyingProductCodeOptions_Get} from "../functions3/UnderlyingProductCodeOptions_Get.js";
import {ExpiryOptions_Get} from "../functions3/ExpiryOptions_Get.js";
import {CombinationOptions_Get} from "../functions3/CombinationOptions_Get.js";

export default function Training3(props){

    const [Country, setCountry] = useState('HK');
    const [ProductType, setProductType] = useState('');
    const [ProductTypeOptions, setProductTypeOptions] = useState('');    
    const [UnderlyingProductCode, setUnderlyingProductCode] = useState('');
    const [UnderlyingProductCodeOptions, setUnderlyingProductCodeOptions] = useState('');
    const [Expiry, setExpiry] = useState('');
    const [ExpiryOptions, setExpiryOptions] = useState('');
    // const [Combination, setCombination] = useState('');
    const [CombinationOptions, setCombinationOptions] = useState('');

    ////////////////////////////////////////////////// Old

    const [CombinationID, setCombinationID] = useState('_01_LongCall');
    const [UnderlyingProductLast, setUnderlyingProductLast] = useState('-1');
    const [ListOptionDetailsList, setListOptionDetailsList] = useState([]);
    const [BaseContract, setBaseContract] = useState(1);
    const [TimeStatus, setTimeStatus] = useState('Current');
    const [Multiplier, setMultiplier] = useState(0);
    const [CombinationListItem123, setCombinationListItem123] = useState({});
    const [DisplayXReferenceLineUnderlyingProductValue, setDisplayXReferenceLineUnderlyingProductValue] = useState(false);
    const [DisplayDetails, setDisplayDetails] = useState(false);

    function CombinationListItem_Get(){

        var v_http_post_data = {
            Credential: AuthenticationService.getCurrentUser(),
            CombinationID: CombinationID,
        }
      
        const url = sessionStorage.getItem('SiteURL') + '/api/CombinationListItem/Get/';
      
        axios.post(url, v_http_post_data)

        .then(resp => {
            var V_CombinationListItem = resp.data;
            setCombinationListItem123(V_CombinationListItem);
        }); 

    }

    useEffect(() => {    
        CombinationListItem_Get();
    }, [CombinationID]);    

    ////////////////////////////////////////////////// Old

    const C_ProductType = {
        ProductType: ProductType,
        ProductTypeOptions: ProductTypeOptions,
        setProductType: setProductType,
        setProductTypeOptions: setProductTypeOptions,
        ProductTypeOptions_Get: ProductTypeOptions_Get,
    }

    const C_UnderlyingProductCode = {
        UnderlyingProductCode: UnderlyingProductCode,
        UnderlyingProductCodeOptions: UnderlyingProductCodeOptions,
        setUnderlyingProductCode: setUnderlyingProductCode,
        setUnderlyingProductCodeOptions: setUnderlyingProductCodeOptions,
        UnderlyingProductCodeOptions_Get: UnderlyingProductCodeOptions_Get,
    }

    const C_Expiry = {
        Expiry: Expiry,
        ExpiryOptions: ExpiryOptions,
        setExpiry: setExpiry,
        setExpiryOptions: setExpiryOptions,
        ExpiryOptions_Get: ExpiryOptions_Get,
    }

    const C_Combination = {
        CombinationID: CombinationID,
        CombinationOptions: CombinationOptions,
        setCombinationID: setCombinationID,
        setCombinationOptions: setCombinationOptions,
        CombinationOptions_Get: CombinationOptions_Get,
    }

    function onClick_DisplayXReferenceLineUnderlyingProductValue(){
        setDisplayXReferenceLineUnderlyingProductValue(!DisplayXReferenceLineUnderlyingProductValue);
    }

    function onClick_DisplayDetails(){
        setDisplayDetails(!DisplayDetails);
    }

    function onClick_Submit(){

        var v_http_post_data = {
            Credential: AuthenticationService.getCurrentUser(),
            UnderlyingProductCode: UnderlyingProductCode,
            TimeStatus: TimeStatus,
        }
      
        let url;
      
        switch(ProductType){
            case 'IndexOption':
              url = sessionStorage.getItem('SiteURL') + 'api/IndexListItem/IndexCode/';
              break;
            case 'StockOption':
              url = sessionStorage.getItem('SiteURL') + 'api/StockListItem/StockCode/';
              break;
        }
          
        axios.post(url, v_http_post_data)
      
        .then(resp => {
            var V_ListItem = resp.data;
            setMultiplier(V_ListItem.Multiplier);
            var V_UnderlyingProductLastFloat = parseFloat(UnderlyingProductLast);
            setUnderlyingProductLast(V_UnderlyingProductLastFloat);
            if (V_UnderlyingProductLastFloat > 0) ListOptionDetailsList_Get2(ProductType, UnderlyingProductCode, Expiry, BaseContract, TimeStatus, UnderlyingProductLast, setListOptionDetailsList);
        });        

    }

    function onChange_UnderlyingProductLast(P_UnderlyingProductLast){
        setUnderlyingProductLast(P_UnderlyingProductLast);
    }  

    useEffect(() => {
        ProductTypeOptions_Get(
            Country, 
            C_ProductType, 
            C_UnderlyingProductCode, 
            C_Expiry);        
    }, [Country]);

    useEffect(() => {    
        UnderlyingProductCodeOptions_Get(
            Country, 
            ProductType, 
            C_UnderlyingProductCode, 
            C_Expiry);
    }, [ProductType]);

    useEffect(() => {    
        ExpiryOptions_Get(
            ProductType, 
            UnderlyingProductCode, 
            C_Expiry);
    }, [UnderlyingProductCode]);

    useEffect(() => {    
        CombinationOptions_Get(
            C_Combination);
    }, []);

    return(
        <div>
            <ButtonAppBar />
            <Select3 
                Country={Country} 
                Country_Callback={setCountry} 
                ProductType={ProductType} 
                ProductTypeOptions={ProductTypeOptions} 
                ProductType_Callback={setProductType} 
                UnderlyingProductCode={UnderlyingProductCode} 
                UnderlyingProductCodeOptions={UnderlyingProductCodeOptions} 
                UnderlyingProductCode_Callback={setUnderlyingProductCode} 
                Expiry={Expiry} 
                ExpiryOptions={ExpiryOptions}
                Expiry_Callback={setExpiry}
            />
            <br/>
            <Select_Combination 
                CombinationID={CombinationID} 
                CombinationOptions={CombinationOptions} 
                CombinationID_Callback={setCombinationID}
            />
            <br/>
            <TextField id='UnderlyingProductLast' label='UnderlyingProductLast' defaultValue={UnderlyingProductLast} onChange={e => onChange_UnderlyingProductLast(e.target.value)}/>
            <br/><br/>
            <Grid item xs={3}>
                <FormControlLabel control={<Checkbox />} label="DisplayXReferenceLineUnderlyingProductValue" onClick={e => onClick_DisplayXReferenceLineUnderlyingProductValue()}/>
            </Grid>
            <Grid item xs={3}>
                <FormControlLabel control={<Checkbox />} label="DisplayDetails" onClick={e => onClick_DisplayDetails()}/>
            </Grid>            
            <br/><br/>
            <Button variant="contained" onClick={onClick_Submit}>Submit</Button>     
            <br/><br/>
            <CombinationListItem 
                CombinationListItem={CombinationListItem123} 
                ListOptionDetailsList={ListOptionDetailsList} 
                Left7={true} 
                Left6={true} 
                Left5={true} 
                Left4={true} 
                Left3={true} 
                Left2={true} 
                Left1={true} 
                Center={true} 
                Right1={true} 
                Right2={true} 
                Right3={true} 
                Right4={true} 
                Right5={true} 
                Right6={true} 
                Right7={true} 
                ProductType={ProductType} 
                ProductType4UnderlyingProductValue={ProductType} 
                UnderlyingProductCode={UnderlyingProductCode} 
                UnderlyingProductValue={UnderlyingProductLast} 
                Expiry={Expiry} 
                Multiplier={Multiplier}
                BaseContract={BaseContract} 
                DisplayXReferenceLineUnderlyingProductValue={DisplayXReferenceLineUnderlyingProductValue}
                DisplayDetails={DisplayDetails}
                ShowLeft={true}
                ShowCenter={false}
                ShowRight={false}
            />                    
        </div>
    )

}