import React from 'react';
import {useState, useEffect, createContext, useContext, useCallback} from "react";

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';

function Select_Expiry(props){

    const{
        Expiry,
        ExpiryOptions,
        Expiry_Callback,
    } = props;

    function onChange_Expiry(P_Expiry){
        Expiry_Callback(P_Expiry);
    }

    return(
        <Grid item xs={3}>
            <FormControl style={{ width: '100%' }}>
                <InputLabel id="ExpiryLabel">Expiry</InputLabel>
                <Select native='true' value={Expiry} name="Expiry" id="Expiry" labelId="ExpiryLabel" onChange={e => onChange_Expiry(e.target.value)}>
                    {ExpiryOptions}
                </Select>
            </FormControl>
        </Grid>
    )

}

export{
    Select_Expiry,
};