import React from 'react';
import {useState, useEffect, createContext, useContext, useCallback} from "react";
import axios from 'axios';

import AuthenticationService from '../AuthenticationService.js';

function CombinationOptions_Get(C_Combination){

    var v_http_post_data = {
        Credential: AuthenticationService.getCurrentUser(),
    }
  
    const url = sessionStorage.getItem('SiteURL') + 'api/CombinationList/All/';
  
    axios.post(url, v_http_post_data)

    .then(resp => {
        const C_CombinationList = resp.data;
        const C_CombinationOptions = C_CombinationList.map((e, key) => {
            return <option key={key} value={e.ID}>{e.ID}</option>;
        });
        C_Combination.setCombinationID(C_CombinationList[0]);
        C_Combination.setCombinationOptions(C_CombinationOptions);
    }); 

}

export{
    CombinationOptions_Get,
};