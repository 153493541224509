// CombinationDetailsReturn.js

import React from 'react';
import axios from 'axios';
import { useState, useEffect, createContext, useContext } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine, ReferenceDot } from 'recharts';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import LineChart1 from './LineChart1.js';
import AccordionTooltip from './AccordionTooltip.js';
import AuthenticationService from '../AuthenticationService.js';
import ProductBarInfo from './ProductBarInfo.js';
import { LabelPointDollar, StartEnd2, AlertsSet2, AlertsSet, Number2Dollar, LongUnderlyingProductValue, LongExpiry } from './Common.js';

export default function CombinationDetailsReturn(props){

    const { 
        CombinationDetails,
        ProductType,
        Position,
        CombinationID,
        UnderlyingProductCode,
        Expiry,
        Multiplier,
        BaseContract,
        TimeStatus,
        DisplayXReferenceLineUnderlyingProductValue,
        DisplayDetails,
    } = props;

    // const [Domain, setDomain] = useState('');
    // const [XReferenceLines, setXReferenceLines] = useState([]);
    // const [YReferenceLines, setYReferenceLines] = useState([]);
    // const [XReferenceDots, setXReferenceDots] = useState([]);
    // const [XReferenceLineUnderlyingProductValue, setXReferenceLineUnderlyingProductValue] = useState('');
    // const [YReferenceLineZero, setYReferenceLineZero] = useState('');
    // const [Alerts, setAlerts] = useState('');
    // const [chartdata, setchartdata] = useState('');    
    // const [BaseContract, setBaseContract] = useState(1);

    function SelectAction() {

        var v_http_post_data = {
            Credential: AuthenticationService.getCurrentUser(),
            CombinationID: CombinationID, //
            ProductType: ProductType, //
            UnderlyingProductCode: UnderlyingProductCode, //
            Expiry: Expiry, //
            Position: Position, //
            BaseContract: BaseContract, //
            TimeStatus: TimeStatus, //
            Last: -1,
        }
    
        const url = sessionStorage.getItem('SiteURL') + 'api/OptionDetailsList/OptionStrategyFinder/';
    
        axios.post(url, v_http_post_data)
    
        .then(resp => {
    
        });
    
    }

    let _LegList = CombinationDetails.LegList;

    let _MaxProfitString = CombinationDetails.MaxProfitString;
    let _MaxLossString = CombinationDetails.MaxLossString;
    let _RatioString = CombinationDetails.RatioString;

    _MaxProfitString == 'Unlimited' ? CombinationDetails.MaxProfitString = '無限' : CombinationDetails.MaxProfitString = _MaxProfitString;
    _MaxLossString == 'Unlimited' ? CombinationDetails.MaxLossString = '無限' : CombinationDetails.MaxLossString = _MaxLossString;
    _RatioString == 'N/A' ? CombinationDetails.RatioString = '不適用' : CombinationDetails.RatioString = _RatioString;

    const _Alerts = AlertsSet2(_LegList, Multiplier, ProductType);

    let data = [];
    const OptionXYList = CombinationDetails.OptionXYList;

    let _domain_start, _domain_end;
    let _domain;
    let _color;
    let _legend;
    let _x, _y, _x_string;
    let _reference_line;
    let _x_reference_line_underlying_product_value;
    let _x_reference_dot_breakeven_point;
    let _y_reference_line_zero;
    let _x_reference_lines = [];
    let _y_reference_lines = [];
    let _x_reference_dots = [];

    let _YMinMaxDifference = OptionXYList.YMax - OptionXYList.YMin;
    let _delta = _YMinMaxDifference / 4;

    _domain_start = OptionXYList.YMin - _delta;
    _domain_end = OptionXYList.YMax + _delta;
    _domain = [_domain_start, _domain_end];

    if (OptionXYList.XYList[0].X != 0){

        var i;

        for (i = 0; i < OptionXYList.XYList.length; i++){
            const x = OptionXYList.XYList[i].X;
            const y = OptionXYList.XYList[i].Y;
            let _xy = {};
            _xy.x = x;
            _xy.y = y;
            data.push(_xy);
        }

        const MarkerXYList = CombinationDetails.OptionXYList.MarkerXYList;

        for (i=0; i<MarkerXYList.length; i++){

            switch(MarkerXYList[i].XY){

                case 'X':

                    _x = MarkerXYList[i].Point;
                    _x_string = LongUnderlyingProductValue(ProductType, _x);
        
                    switch(ProductType)
                    {
                        case 'IndexOption':
                            _x = parseInt(_x.toFixed(0));
                            break;
                        case 'StockOption':
                            _x = parseFloat(_x.toFixed(2));
                            break;
                    }

                    switch(MarkerXYList[i].PointType){

                        case 'UnderlyingProductValue':

                            if (DisplayXReferenceLineUnderlyingProductValue) {
                                _x_reference_line_underlying_product_value = <ReferenceLine x={_x} label={{ value: _x_string, angle: 0, position: 'insideBottomRight', fill: 'red'}} stroke="red" />
                            }
                        
                            break;

                        case 'BreakevenPoint':

                            // _x = MarkerXYList[i].Point;
                            // _x_string = LongUnderlyingProductValue(ProductType, _x);
                            // _x = _x.toFixed(0); // x in ReferenceLine doesn't accept decimal, don't know why
                            // _x = parseFloat(_x);
                            _x_reference_dot_breakeven_point = <ReferenceDot x={_x} y={0} label={{ value: _x_string }} r={1} />
                            _x_reference_dots.push(_x_reference_dot_breakeven_point);
                            break;

                        default:

                            // _x = MarkerXYList[i].Point;
                            // _x_string = LongUnderlyingProductValue(ProductType, _x);
                            // _x = _x.toFixed(0); // x in ReferenceLine doesn't accept decimal, don't know why
                            _reference_line = <ReferenceLine x={_x} label={{ value: _x_string, angle: 90, position: 'insideTopRight', fill: 'lightgray' }} stroke="lightgray" />
                            _x_reference_lines.push(_reference_line);
                            break;

                        }

                    break;

                case 'Y':

                    switch(MarkerXYList[i].PointType){

                        case 'Zero':

                            _y = MarkerXYList[i].Point;

                            let _value;
                            switch(ProductType){        
                                case 'IndexOption':
                                    _value = '$0';
                                    break;
                                case 'StockOption':
                                    _value = '$0.00';
                                    break;
                            }

                            _y_reference_line_zero = <ReferenceLine y={0} label={{ value: _value, angle: 0, position: 'insideTopLeft', fill: 'red'}} stroke="red"/>;
                            
                            break;

                        case 'Max':

                            _y = MarkerXYList[i].Point;
                            let _y_max_string = Number2Dollar(_y, 0);
                            _reference_line = <ReferenceLine y={_y} label={{ value: '最高值：' + _y_max_string, angle: 355, position: 'insideTopRight', fill: 'lightgray'}} stroke="lightgray"/>;
                            _y_reference_lines.push(_reference_line);
                            break;

                        case 'Min':

                            _y = MarkerXYList[i].Point;
                            let _y_min_string = Number2Dollar(_y, 0);
                            _reference_line = <ReferenceLine y={_y} label={{ value: '最低值：' + _y_min_string, angle: 355, position: 'insideTopRight', fill: 'lightgray'}} stroke="lightgray"/>;
                            _y_reference_lines.push(_reference_line);
                            break;

                        }

                    break;

            }

        }

        // setDomain(_domain);
        // setXReferenceLines(_x_reference_lines);
        // setYReferenceLines(_y_reference_lines);
        // setXReferenceDots(_x_reference_dots);
        // setXReferenceLineUnderlyingProductValue(_x_reference_line_underlying_product_value);
        // setYReferenceLineZero(_y_reference_line_zero);
        // setAlerts(_Alerts);
        // setchartdata(data);

    }

    if (CombinationDetails != null & DisplayDetails){

        const TotalCost = StartEnd2('Total Cost:', CombinationDetails.TotalCostString, 6, 6);
        const TotalValue = StartEnd2('Total Value:', CombinationDetails.TotalValueString, 6, 6);
        const TotalProfit = StartEnd2('Total Profit:', CombinationDetails.TotalProfitString, 6, 6);   

        return(

            <div>
                <Card style={{ border: "none", boxShadow: "none"}}>
    
                    <CardMedia>
                        <LineChart1
                            Position={Position}
                            chartdata={data}
                            Domain={_domain}
                            XReferenceLines={_x_reference_lines}
                            YReferenceLines={_y_reference_lines}
                            XReferenceDots={_x_reference_dots}
                            XReferenceLineUnderlyingProductValue={_x_reference_line_underlying_product_value}
                            YReferenceLineZero={_y_reference_line_zero}
                        />
                    </CardMedia>
    
                    <CardContent>
    
                        <br />
    
                        {_Alerts}

                        {/* {CombinationDetails.LegList.map((e, key) => {(                            
                            <Grid item xs={12} lg={12}>
                                <ProductBarInfo
                                Contract={e.Contract}
                                ProductCode={e.ProductCode}
                                CallPut={e.CallPut}
                                BidAsk={e.BidAsk}
                                RevisionDateTimeHKBidAsk={e.BidAskDateTime}
                                />  
                            </Grid>
                        )})} */}
                        <br />
                        <Divider variant="middle" />
                        <br /><br />

                        {/* <AccordionTooltip Summary={StartEnd2('開倉值：', CombinationDetails.TotalCostString, 6, 6)} /> */}
                        <AccordionTooltip Summary={LabelPointDollar('開倉值：', null, ProductType, CombinationDetails.TotalCost/Multiplier, Multiplier, false, null, 6, 6, 0)} />
                        
                        {/* <AccordionTooltip Summary={StartEnd2('平倉值：', CombinationDetails.TotalValueString, 6, 6)} /> */}
                        <AccordionTooltip Summary={LabelPointDollar('平倉值：', null, ProductType, CombinationDetails.TotalValue/Multiplier, Multiplier, false, null, 6, 6, 0)} />

                        {/* <AccordionTooltip Summary={StartEnd2('平倉利潤：', CombinationDetails.TotalProfitString, 6, 6)} Details={CombinationDetails.TotalProfitToolTip}/> */}
                        <AccordionTooltip Summary={LabelPointDollar('平倉利潤：', null, ProductType, CombinationDetails.TotalProfit/Multiplier, Multiplier, false, null, 6, 6, 0)}/>
                        <br/>
                        {/* <AccordionTooltip Summary={StartEnd2('內在值：', CombinationDetails.TotalIValString, 6, 6)} /> */}
                        <AccordionTooltip Summary={LabelPointDollar('內在值：', null, ProductType, CombinationDetails.TotalIVal/Multiplier, Multiplier, false, null, 6, 6, 0)} />

                        {/* <AccordionTooltip Summary={StartEnd2('結算利潤：', CombinationDetails.IvalProfitString, 6, 6)} Details={CombinationDetails.IvalProfitToolTip}/> */}
                        <AccordionTooltip Summary={LabelPointDollar('結算利潤：', null, ProductType, CombinationDetails.IvalProfit/Multiplier, Multiplier, false, null, 6, 6, 0)}/>
                        
                        <br/>

                        <AccordionTooltip Summary={StartEnd2('最大利潤：', CombinationDetails.MaxProfitString, 6, 6)} Details={CombinationDetails.MaxProfitToolTip}/>
                        <AccordionTooltip Summary={StartEnd2('最大虧損：', CombinationDetails.MaxLossString, 6, 6)} Details={CombinationDetails.MaxLossToolTip}/>
                        <AccordionTooltip Summary={StartEnd2('損益比例：', CombinationDetails.RatioString, 6, 6)} Details={CombinationDetails.RatioToolTip}/>

                        {/* <AccordionTooltip Summary={StartEnd2('打和點：', CombinationDetails.ZeroPositioinString, 6, 6)} Details={CombinationDetails.ZeroPositioinToolTip}/> */}
                        <AccordionTooltip Summary={LabelPointDollar('打和點：', null, ProductType, CombinationDetails.ZeroPositioin, null, false, null, 6, 6, 0)} Details={CombinationDetails.ZeroPositioinToolTip}/>

                        <br /><br />
                        <Divider variant="middle" />
                        <br/><br />
                        <Button variant="contained" color="error" fullWidth="true" onClick={SelectAction}>加入我的清單</Button>
    
                    </CardContent>
    
                </Card>
    
            </div>
    
        )

    }
    else if (CombinationDetails != null & !DisplayDetails){
    
        const TotalCost = StartEnd2('Total Cost:', CombinationDetails.TotalCostString, 6, 6);
        const TotalValue = StartEnd2('Total Value:', CombinationDetails.TotalValueString, 6, 6);
        const TotalProfit = StartEnd2('Total Profit:', CombinationDetails.TotalProfitString, 6, 6);   

        return(

            <div>
    
                <Card style={{ border: "none", boxShadow: "none" }}>
    
                    <CardMedia>
                        <LineChart1
                            Position={Position}
                            chartdata={data}
                            Domain={_domain}
                            XReferenceLines={_x_reference_lines}
                            YReferenceLines={_y_reference_lines}
                            XReferenceDots={_x_reference_dots}
                            XReferenceLineUnderlyingProductValue={_x_reference_line_underlying_product_value}
                            YReferenceLineZero={_y_reference_line_zero}
                        />
                    </CardMedia>
    
                    {/* <CardContent>
    
                        <br />
    
                        {_Alerts}

                        <br />
                        <Divider variant="middle" />
                        <br /><br />

                        <AccordionTooltip Summary={LabelPointDollar('開倉值：', null, ProductType, CombinationDetails.TotalCost/Multiplier, Multiplier, false, null, 6, 6, 0)} />
                        <AccordionTooltip Summary={LabelPointDollar('平倉值：', null, ProductType, CombinationDetails.TotalValue/Multiplier, Multiplier, false, null, 6, 6, 0)} />
                        <AccordionTooltip Summary={LabelPointDollar('平倉利潤：', null, ProductType, CombinationDetails.TotalProfit/Multiplier, Multiplier, false, null, 6, 6, 0)}/>
                        <br/>
                        <AccordionTooltip Summary={LabelPointDollar('內在值：', null, ProductType, CombinationDetails.TotalIVal/Multiplier, Multiplier, false, null, 6, 6, 0)} />
                        <AccordionTooltip Summary={LabelPointDollar('結算利潤：', null, ProductType, CombinationDetails.IvalProfit/Multiplier, Multiplier, false, null, 6, 6, 0)}/>
                        <br/>

                        <AccordionTooltip Summary={StartEnd2('最大利潤：', CombinationDetails.MaxProfitString, 6, 6)} Details={CombinationDetails.MaxProfitToolTip}/>
                        <AccordionTooltip Summary={StartEnd2('最大虧損：', CombinationDetails.MaxLossString, 6, 6)} Details={CombinationDetails.MaxLossToolTip}/>
                        <AccordionTooltip Summary={StartEnd2('損益比例：', CombinationDetails.RatioString, 6, 6)} Details={CombinationDetails.RatioToolTip}/>
                        <AccordionTooltip Summary={LabelPointDollar('打和點：', null, ProductType, CombinationDetails.ZeroPositioin, null, false, null, 6, 6, 0)} Details={CombinationDetails.ZeroPositioinToolTip}/>

                        <br /><br />
                        <Divider variant="middle" />
                        <br/><br />
                        <Button variant="contained" color="error" fullWidth="true" onClick={SelectAction}>加入我的清單</Button>
    
                    </CardContent> */}
    
                </Card>
    
            </div>
    
        )
    
    }
    else{
        return(
            <React.Fragment>
            </React.Fragment>
        )
    }

}