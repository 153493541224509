import React from 'react';
import axios from "axios";

class AuthenticationService extends React.Component {

    signin = async (UserName, Password) => {

        var v_user_list_item = {
            UserName: UserName,
            Password: Password,
        };
    
        const url = sessionStorage.getItem('SiteURL') + '/api/UserListItem/Token/Get/';
        //const url = 'api/UserListItem/Token/Get/';

        // const settings = {
        //     method: 'POST',
        //     headers: {
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/json',
        //     },
        //     mode:'cors',
        //     body: JSON.stringify()
        // }

        try {
            const response = await axios.post(url, v_user_list_item);
            return response.data;

            // const response = await fetch(url, settings);
            // const data = await response.json();
            // return data;

        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    getCurrentUser() {
        return JSON.parse(sessionStorage.getItem('Credential'));
    }

}

export default new AuthenticationService();