// CascadingDropDownList2.js

import React from 'react';
import { useState, useEffect, createContext, useContext, useCallback } from "react";
import axios from 'axios';
import { useRoutes, A, navigate } from "hookrouter";

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { InputLabel } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import Button from '@mui/material/Button';

import AuthenticationService from '../AuthenticationService.js';
import { LongUnderlyingProductValue, LongExpiry, ShortExpiry } from './Common.js';

export default function CascadingDropDownList2(props){

    const { 
        ProductTypeUnderlyingProductCodeExpiryList,
        stateCallback,
        ProductType,
        ProductType_Callback, 
        UnderlyingProductCode,
        UnderlyingProductCode_Callback,
        Expiry,
        Expiry_Callback, 
        TimeStatus,
        UnderlyingProductCodeOptions,
        ExpiryOptions,
        NoUnderlyingProductCode,
        NoExpiry,
        IndexListOptionEnabled,
        StockListOptionEnabled
    } = props;

    // const [ProductType, setProductType] = useState(ProductType);
    // const [UnderlyingProductCode, setUnderlyingProductCode] = useState('');
    // const [Expiry, setExpiry] = useState('');

    function onChange_ProductType(P_ProductType){
        // setProductType(P_ProductType);
        ProductType_Callback(P_ProductType);
    }

    function onChange_UnderlyingProductCode(P_UnderlyingProductCode){
        // setUnderlyingProductCode(P_UnderlyingProductCode);
        UnderlyingProductCode_Callback(P_UnderlyingProductCode);
    }

    function onChange_Expiry(P_Expiry){
        // setExpiry(P_Expiry);
        Expiry_Callback(P_Expiry);
    }

    const Select_ProductType = useCallback(() => {
        return(
            <Grid item xs={6}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="ProductTypeLabel">期權類別</InputLabel>
                    <Select native='true' value={ProductType} name="ProductType" id="ProductType" labelId="ProductTypeLabel" onChange={e => onChange_ProductType(e.target.value)}>
                        <option key={0} value={'IndexOption'}>指數期權</option>
                        <option key={1} value={'StockOption'}>股票期權</option>
                    </Select>
                </FormControl>
            </Grid>
        )
    }, [ProductType]);

    const Select_UnderlyingProductCode = useCallback(() => {

        if (NoUnderlyingProductCode == false){
            return(
                <Grid item xs={6}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel id="UnderlyingProductLabel">相關產品</InputLabel>
                        <Select native='true' value={UnderlyingProductCode} name="UnderlyingProductCode" id="UnderlyingProductCode" labelId="UnderlyingProductLabel" onChange={e => onChange_UnderlyingProductCode(e.target.value)}>
                            {UnderlyingProductCodeOptions}
                        </Select>
                    </FormControl>
                </Grid>
            )
        }
        else{
            return(
                <React.Fragment>          
                </React.Fragment>
            )  
        }

    }, [UnderlyingProductCode]);

    const Select_Expiry = useCallback(() => {

        if (NoExpiry == false){

            return(
                <Grid item xs={12}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel id="ExpiryLabel">到期日</InputLabel>
                        <Select native='true' value={Expiry} name="Expiry" id="Expiry" labelId="ExpiryLabel" onChange={e => onChange_Expiry(e.target.value)}>
                            {ExpiryOptions}
                        </Select>
                    </FormControl>         
                </Grid>   
            )

        }
        else{
            return(
                <React.Fragment>          
                </React.Fragment>
            )            
        }

    }, [Expiry]);

    useEffect(() => {

        if (UnderlyingProductCodeOptions.length > 0){
            var V_UnderlyingProductCode = UnderlyingProductCodeOptions[0].props.value;
            onChange_UnderlyingProductCode(V_UnderlyingProductCode);
        } 

        if (ExpiryOptions.length > 0){
            var V_Expiry = ExpiryOptions[0].props.value;
            onChange_Expiry(V_Expiry);
        } 

    }, [UnderlyingProductCodeOptions]);

    useEffect(() => {

        if (ExpiryOptions.length > 0){
            var V_Expiry = ExpiryOptions[0].props.value;
            onChange_Expiry(V_Expiry);
        } 

    }, [ExpiryOptions]);

    return(
        <div>
            <Grid container spacing={1}>
                <Select_ProductType />
                <Select_UnderlyingProductCode />
                <Select_Expiry />
                <br/><br/><br/><br/>
            </Grid>
        </div>
    )

}